import React, { useState } from "react";
import "./Header.css";
import { Fade } from "react-reveal";
//import { HashLink } from "react-router-hash-link";

import { NavLink, Link } from "react-router-dom";
import { greeting } from "../../portfolio.js";
import { CgSun } from "react-icons/cg/";
import { HiMoon } from "react-icons/hi";
import { style } from "glamor";

function Header(props) {
  const theme = props.theme;

  const styles = style({
    cursor: "pointer",
    height: "45px",
    width: "45px",
    marginRight: "5px",
    marginLeft: "15px",
    paddingTop: "5px",
    borderRadius: "50%",
    border: "none",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.body,
    outline: "none",
    transition: "all 0.2s ease-in-out",
    ":hover": {
      //border: `3px solid ${props.theme.name === "light" ? "#0a1612" : "#f5f1ed"}`
      boxShadow: `0px 0px 10px -1px ${theme.text}`,
    },
  });

  /* TODO: Add back in once ready
  const headerLink = style({
    cursor: "pointer",
    margin: "2px 5px 20px",
    transition: "all 0.3s ease-in-out",
    borderRadius: 5,
    color: theme.text,
    ":active": {
      fontWeight: "bold",
    },
    ":hover": {
      boxShadow: `0 2px 10px ${theme.accentColor}`,
      backgroundColor: `${theme.accentColor}`,
      color: "#ffffff",
    },
  });
  */

  const [currTheme, setCurrTheme] = useState(props.theme);

  function changeTheme() {
    if (currTheme === "light") {
      props.setTheme("dark");
      localStorage.setItem("theme", "dark");
      setCurrTheme("dark");
    } else {
      props.setTheme("light");
      localStorage.setItem("theme", "light");
      setCurrTheme("light");
    }
  }

  const icon =
    props.theme.name === "dark" ? (
      <HiMoon strokeWidth={1} size={20} color={theme.text} />
    ) : (
      <CgSun strokeWidth={1} size={20} color={theme.text} />
    );

  return (
    <Fade top duration={1000} distance="20px">
      <div className="header-main">
        <header className="header">
          <NavLink to={"/"} tag={Link} className="logo">
            <span style={{ color: theme.text }}></span>
            <span className="logo-name" style={{ color: theme.text }}>
              {greeting.logo_name}
            </span>
            <span style={{ color: theme.text }}></span>
          </NavLink>
          <input className="menu-btn" type="checkbox" id="menu-btn" />
          <label className="menu-icon" htmlFor="menu-btn">
            <span className="navicon"></span>
          </label>
          <ul className="menu">
            {/* TODO: Add back in once ready
            <li>
              <HashLink
                smooth
                to="/home#about"
                {...headerLink}
              >
                About
              </HashLink>
            </li>
            <li>
              <HashLink
                smooth
                to="/home#skills"
                {...headerLink}
              >
                Skills
              </HashLink>
            </li>
            <li>
              <HashLink
                smooth
                to="/home#experience"
                {...headerLink}
              >
                Experience
              </HashLink>
            </li>
            <li>
              <HashLink
                smooth
                to="/home#education"
                {...headerLink}
              >
                Education
              </HashLink>
            </li>
            <li>
              <NavLink
                to="/contact"
                {...headerLink}
              >
                Contact
              </NavLink>
            </li>
            */}
            <button {...styles} onClick={changeTheme}>
              {icon}
            </button>
          </ul>
        </header>
      </div>
    </Fade>
  );
}

export default Header;
