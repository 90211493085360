// Theme Presets

const lightTheme = {
  name: "light",
  body: "#e7e7e7",
  text: "#171c28",
  dark: "#000000",
  secondaryText: "#252525",
  accentColor: "#be5536",
  accentBright: "#c7502d",
  skinColor: "#F7B799",
  skinColor2: "#FCB696",
  imageDark: "#343d52",
  imageClothes: "#212121",
  avatarMisc: "#212121",
  avatarShoes: "#a3a8b5",
};

const darkTheme = {
  name: "dark",
  body: "#171c28",
  text: "#e7e7e7",
  dark: "#000000",
  secondaryText: "#b6b6b6",
  accentColor: "#be5536",
  accentBright: "#c7502d",
  skinColor: "#F7B799",
  skinColor2: "#FCB696",
  imageDark: "#343d52",
  imageClothes: "#212121",
  avatarMisc: "#212121",
  avatarShoes: "#a3a8b5",
};

export const themes = { light: lightTheme, dark: darkTheme };
