import React from "react";
import { Route, Switch, HashRouter } from "react-router-dom";

import Splash from "../pages/splash/Splash";
import HomePage from "../pages/home/HomePage";
import NotFound from "../pages/404/NotFound";

export default function Main(propss) {
  return (
    <div>
      <HashRouter basename="/">
        <Switch>
          <Route
            path="/"
            exact
            render={(props) => (
              <Splash
                {...props}
                theme={propss.theme}
                setTheme={propss.setTheme}
              />
            )}
          />
          <Route
            path="/home"
            exact
            render={(props) => (
              <HomePage
                {...props}
                theme={propss.theme}
                setTheme={propss.setTheme}
              />
            )}
          />
          <Route
            path="*"
            render={(props) => (
              <NotFound
                {...props}
                theme={propss.theme}
                setTheme={propss.setTheme}
              />
            )}
          />
        </Switch>
      </HashRouter>
    </div>
  );
}
