import React from "react";

import Header from "../../components/header/Header";
import Greeting from "../../containers/greeting/Greeting";
//import About from "../../containers/about/About";
//import Skills from "../../containers/newskills/Skills";
//import Experience from "../../containers/experience/Experience";
//import Education from "../../containers/education/Education";
//import Footer from "../../components/footer/Footer";
import ScrollButton from "../../components/scrollButton/ScrollButton";

function HomePage(props) {
  const { theme, setTheme } = props;

  return (
    <div>
      <Header theme={theme} setTheme={setTheme} />
      <Greeting theme={theme} />
      {/* TODO: Add back in once ready
      <About theme={theme} />
      <Skills theme={theme} />
      <Experience theme={theme} />
      <Education theme={theme} />
      <Footer theme={theme} />
      */}
      <ScrollButton theme={theme} />
    </div>
  );
}

export default HomePage;
