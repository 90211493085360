import React, { useEffect, useState } from "react";
import "./ScrollButton.css";
import Button from "react-bootstrap/Button";
import { style } from "glamor";

export default function ScrollButton(props) {
  const { theme } = props;
  const [showButton, setShowButton] = useState(false);

  const buttonStyle = style({
    cursor: "pointer",
    position: "fixed",
    bottom: "50px",
    right: "50px",
    width: "50px",
    height: "50px",
    fontSize: "2rem",
    borderRadius: "100%",
    border: "none",
    color: `${theme.text}`,
    background: "rgba(76, 175, 80, 0.0)",
    ":hover": {
      opacity: "100%",
      color: `#ffffff`,
      background: `${theme.accentBright}`,
    },
  });

  useEffect(() => {
    window.addEventListener("scroll", () => {
      console.log(window.pageYOffset);
      if (window.pageYOffset > 200) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // for smoothly scrolling
    });
  };

  const upArrowIcon = (
    <span className="iconify" data-icon="fa-angle-up" data-inline="false" />
  );

  return (
    <div className="scroll-button-div">
      {showButton && (
        <Button className="wiggle" onClick={scrollToTop} {...buttonStyle}>
          {upArrowIcon}
        </Button>
      )}
    </div>
  );
}
