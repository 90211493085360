import React, { useState, Fragment } from "react";
import "./App.css";
import Main from "./layouts/Main";
import { ThemeProvider } from "styled-components";
import { themes } from "./theme";
import { GlobalStyles } from "./global";

function App() {
  const [theme, setTheme] = useState(
    localStorage.getItem("nwang-theme") || "dark"
  );

  return (
    <ThemeProvider theme={themes[theme]}>
      <Fragment>
        <GlobalStyles />
        <div>
          <Main theme={themes[theme]} setTheme={setTheme} />
        </div>
      </Fragment>
    </ThemeProvider>
  );
}

export default App;
